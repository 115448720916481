<template>
  <div class="layout-topbar" v-if="this.$store.state.auth.status.loggedIn">
    <button
      class="p-link layout-menu-button"
      @click="onMenuToggle"
      v-tooltip="'Mostrar/Ocultar menu'"
    >
      <span class="pi pi-bars"></span>
    </button>
    <div
      class="layout-topbar-icons"
    >
      <button class="p-link" @click="logout()" v-tooltip.left="'Logout'">
        <span class="layout-topbar-icon pi pi-sign-out"></span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>
