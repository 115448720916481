import Vue from "vue";
import Router from "vue-router";
import Login from "../views/Login.vue";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/",
      name: "home",
      component: () => import("../views/Home.vue"),
    },
    {
      path: "/proposal-detail/:id",
      name: "proposal-detail",
      component: () => import("../views/ProposalDetail.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && (!loggedIn || loggedIn == null)) {
    next("/login");
  } else {
    next();
  }
});
