import axios from "axios";
import UserService from "../services/user.service";
import { config } from "../config";
import authHeader from "./auth-header";

class AuthService {
  login(user) {
    return axios
      .post(`${config["apiUrl"]}login`, {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        if (response.status == 200) {
          localStorage.setItem("userToken", JSON.stringify(response.data));
          return UserService.getUserInfo().then((userInfo) => {
            if (userInfo) {
              userInfo["token"] = response.data.token;
              localStorage.setItem("user", JSON.stringify(userInfo));
              return userInfo;
            }
          });
        }
      });
  }

  logout() {
    localStorage.removeItem("user");
    return axios
      .post(
        `${config["apiUrl"]}logout`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then(() => localStorage.removeItem("user"));
  }
}

export default new AuthService();
